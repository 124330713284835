<template>
	<div class="m91 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>약관동의</p>
			</div>
		</div>
		<!-- End of Header Section -->

		<div class="box-mydata top-4">
			<div class="all-agree">
				<p>전체동의</p>
				<div class="custom-control">
					<input
						v-model="checkAll"
						@change="changeListener(true, $event)"
						type="checkbox"
						class="custom-control-input"
						id="mydataAllCheck"
					/>
					<label class="custom-control-label" for="mydataAllCheck"></label>
				</div>
			</div>

			<div class="sub-agree">
				<ul class="sub1-agree data">
					<li>
						<a @click="goPage('MyTerms')">
							<span>(필수) 마이데이터 서비스 이용 동의</span>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
						<div class="custom-control">
							<input
								type="checkbox"
								class="subcustom-control-input"
								id="subMydataCheck"
								v-model="check01"
								@change="changeListener(false, $event)"
							/>
							<label
								class="subcustom-control-label"
								for="subMydataCheck"
							></label>
						</div>
					</li>
					<li>
						<a @click="goPage('PrivacyConsent')">
							<span>(필수) 개인정보 수집 및 이용 동의</span>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
						<div class="custom-control">
							<input
								type="checkbox"
								class="subcustom-control-input"
								id="subMydataCheck1"
								v-model="check02"
								@change="changeListener(false, $event)"
							/>
							<label
								class="subcustom-control-label"
								for="subMydataCheck1"
							></label>
						</div>
					</li>
					<li>
						<a @click="goPage('3rdConsent')">
							<span>(선택) 개인정보 제 3자 제공 동의</span>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
						<div class="custom-control">
							<input
								type="checkbox"
								class="subcustom-control-input"
								id="subMydataCheck2"
								v-model="check03"
								@change="changeListener(false, $event)"
							/>
							<label
								class="subcustom-control-label"
								for="subMydataCheck2"
							></label>
						</div>
					</li>
				</ul>
			</div>
			<!-- End of SubMydata Html Code-->
			<div class="mydata-txt">
				<ul class="roundbox-txt">
					<li>
						<p>개인데이터 활용 목적</p>
						<span>1. 핵심서비스</span>
						<span>2. 제 3자 제공</span>
						<span>3. 향상된 서비스</span>
						<span>4. 연구</span>
						<span>5. 마케팅</span>
					</li>
				</ul>
			</div>
		</div>

		<div class="btn-app">
			<button type="button" class="btn-select" @click="confirm">
				신청하기
			</button>
		</div>
	</div>
</template>

<style scoped>
@import '~@/assets/css/bootstrap.css';
.top-4 {
	padding-top: 4rem;
}
</style>

<script>
export default {
	name: 'MyTostForm',

	data() {
		return {
			check01: false,
			check02: false,
			check03: false,
			checkAll: false,
		};
	},

	methods: {
		changeListener(isAllCheckBox, event) {
			console.debug('changeListener', isAllCheckBox, event);
			if (isAllCheckBox) {
				if (event.target.checked === true) {
					this.check01 = true;
					this.check02 = true;
					this.check03 = true;
				} else {
					this.check01 = false;
					this.check02 = false;
					this.check03 = false;
				}
			} else {
				if (
					this.check01 === true &&
					this.check02 === true &&
					this.check03 === true
				) {
					this.checkAll = true;
				} else {
					this.checkAll = false;
				}
			}
		},

		confirm() {
			alert('준비중입니다.');
		},
	},
};
</script>
