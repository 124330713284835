<template>
	<div class="m79 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>이용약관</p>
			</div>
		</div>
		<!-- End of Header Section -->
		<div class="terms_box top-4">
			<div class="more-menu">
				<ul>
					<!-- 서비스 이용약관 loof -->
					<li>
						<a @click="goPage('PostAppTerms')">
							<h4 class="terms-tit">
								서비스 이용약관
								<span>시행일 2021.06.10</span>
							</h4>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<!-- End of 서비스 이용약관 loof -->
					<!-- 개인정보 수집 및 이용동의 loof -->
					<li>
						<a @click="goPage('PrivacyConsent')">
							<h4 class="terms-tit">
								개인정보 수집 및 이용동의
								<span>시행일 2021.06.10</span>
							</h4>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<!-- End of 개인정보 수집 및 이용동의 loof -->
					<!-- 개인정보 제3자 제공동의 loof -->
					<li>
						<a @click="goPage('3rdConsent')">
							<h4 class="terms-tit">
								개인정보 제3자 제공동의
								<span>시행일 2021.06.10</span>
							</h4>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<!-- End of 개인정보 제3자 제공동의 loof -->
					<!-- 우정서비스 통합회원가입 동의 loof -->
					<li>
						<a @click="goPage('PostAppConsent')">
							<h4 class="terms-tit">
								우정서비스 통합회원가입 동의
								<span>시행일 2021.06.10</span>
							</h4>
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
					</li>
					<!-- End of 우정서비스 통합회원가입 동의 loof -->
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TosList',

	methods: {
		goPage(what) {
			this.$router.push({ name: what });
		},
	},
};
</script>
