<template>
	<div class="m26 wrapper">
		<div class="top-nav">
			<div class="icon-txt">
				<a @click="$router.go(-1)">
					<div class="top-iconBack">
						<img :src="require('@/assets/img/ic_back.svg')" alt="뒤로가기" />
					</div>
				</a>
				<p>약관동의</p>
			</div>
		</div>
		<!-- End of Header Section -->
		<div class="box-agreeContainer top-4">
			<!-- 전체동의 checkBox -->
			<div class="all-agree">
				<p>전체동의</p>
				<div class="custom-control">
					<input
						v-model="registerState.terms.checkAll"
						@change="changeListener(true, $event)"
						type="checkbox"
						class="custom-control-input"
						id="customCheck"
					/>
					<label class="custom-control-label" for="customCheck"></label>
				</div>
			</div>
			<!-- End of 전체동의 checkBox -->
			<!-- 필수 동의 선택 Section -->
			<div class="sub-agree">
				<ul class="sub1-agree">
					<li>
						<a @click="goPage('PostAppTerms')">
							(필수) 우체국앱 서비스 이용약관
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
						<div class="custom-control">
							<input
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check01"
								@change="changeListener(false, $event)"
								id="subcustomCheck1"
							/>
							<label
								class="subcustom-control-label"
								for="subcustomCheck1"
							></label>
						</div>
					</li>
					<li>
						<a @click="goPage('PrivacyConsent')"
							>(필수) 개인정보 수집 및 이용동의
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check02"
								id="subcustomCheck2"
							/>
							<label
								class="subcustom-control-label"
								for="subcustomCheck2"
							></label>
						</div>
					</li>
					<li>
						<a @click="goPage('3rdConsent')">
							(필수) 개인정보 제 3자 제공동의
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check03"
								id="subcustomCheck3"
							/>
							<label
								class="subcustom-control-label"
								for="subcustomCheck3"
							></label>
						</div>
					</li>
					<li>
						<a @click="goPage('MarketingConsent')">
							(선택) 마케팅/홍보에 개인정보 이용동의
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check04"
								id="subcustomCheck4"
							/>
							<label
								class="subcustom-control-label"
								for="subcustomCheck4"
							></label>
						</div>
					</li>
					<li>
						<a @click="goPage('PostAppConsent')">
							(필수) 우정서비스 가입선택 및 동의
							<svg
								version="1.1"
								id="레이어_1"
								xmlns="http://www.w3.org/2000/svg"
								xmlns:xlink="http://www.w3.org/1999/xlink"
								x="0px"
								y="0px"
								viewBox="0 0 100 100"
								style="enable-background: new 0 0 100 100"
								xml:space="preserve"
							>
								<path
									class="st0"
									d="M41.7,25l-5.9,5.9L55,50L35.9,69.1l5.9,5.9l25-25L41.7,25z"
								/>
							</svg>
						</a>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check05"
								id="subcustomCheck5"
							/>
							<label
								class="subcustom-control-label"
								for="subcustomCheck5"
							></label>
						</div>
					</li>
				</ul>
				<!-- 선택 동의 Section -->
				<ul class="sub2-agree">
					<li>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check06"
								id="subcustomCheck6"
							/>
							<label class="subcustom-control-label" for="subcustomCheck6">
								<p>(필수) 우체국 우편, 쇼핑</p>
							</label>
						</div>
					</li>
					<li>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check07"
								id="subcustomCheck7"
							/>
							<label class="subcustom-control-label" for="subcustomCheck7">
								<p>(선택) 모바일 우편함</p>
							</label>
						</div>
					</li>
					<li>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check08"
								id="subcustomCheck8"
							/>
							<label class="subcustom-control-label" for="subcustomCheck8">
								<p>(선택) 우체국 예금</p>
							</label>
						</div>
					</li>
					<li>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check09"
								id="subcustomCheck9"
							/>
							<label class="subcustom-control-label" for="subcustomCheck9">
								<p>(선택) 우체국 보험</p>
							</label>
						</div>
					</li>
					<li>
						<div class="custom-control">
							<input
								@change="changeListener(false, $event)"
								type="checkbox"
								class="subcustom-control-input"
								v-model="registerState.terms.check10"
								id="subcustomCheck10"
							/>
							<label class="subcustom-control-label" for="subcustomCheck10">
								<p>(선택) 포스트 페이</p>
							</label>
						</div>
					</li>
				</ul>
				<!-- End of 선택 동의 Section -->
				<div class="box-shadow"></div>
			</div>
			<!-- 필수/선택 동의 section -->
		</div>
		<!--End of box-agreeContainer -->

		<div class="btn-app">
			<button type="button" class="btn-select" @click="doNextStep">다음</button>
		</div>
	</div>
</template>

<style scoped>
.top-4 {
	padding-top: 4rem;
}
</style>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
export default {
	name: 'TermsOfService',

	computed: {
		...mapGetters(['registerState']),
	},

	created() {
		console.debug('created', this.saveAgreeTermState);
	},

	methods: {
		...mapMutations(['saveAgreeTermState']),
		...mapActions(['doPostSeriveRegister']),

		goPage(what) {
			this.$router.push({ name: what });
		},

		doNextStep() {
			console.debug('doNextStep');
			// 필수항목 동의를 선택하였는지 검사한다.
			if (
				!this.registerState.terms.check01 ||
				!this.registerState.terms.check02 ||
				!this.registerState.terms.check03 ||
				!this.registerState.terms.check05
			) {
				// TODO 경고 메세지 노출
				return;
			}

			// TODO 약관 동의 서버에 저장
			// 서버에 저장 후 store에 저장
			this.saveAgreeTermState(true);
			this.doPostSeriveRegister();
		},

		changeListener(isAllCheckBox, event) {
			console.debug('changeListener', isAllCheckBox, event);
			if (isAllCheckBox) {
				if (event.target.checked === true) {
					this.registerState.terms.check01 = true;
					this.registerState.terms.check02 = true;
					this.registerState.terms.check03 = true;
					this.registerState.terms.check04 = true;
					this.registerState.terms.check05 = true;
					this.registerState.terms.check06 = true;
					this.registerState.terms.check07 = true;
					this.registerState.terms.check08 = true;
					this.registerState.terms.check09 = true;
					this.registerState.terms.check10 = true;
				} else {
					this.registerState.terms.check01 = false;
					this.registerState.terms.check02 = false;
					this.registerState.terms.check03 = false;
					this.registerState.terms.check04 = false;
					this.registerState.terms.check05 = false;
					this.registerState.terms.check06 = false;
					this.registerState.terms.check07 = false;
					this.registerState.terms.check08 = false;
					this.registerState.terms.check09 = false;
					this.registerState.terms.check10 = false;
				}
			} else {
				if (
					this.registerState.terms.check01 === true &&
					this.registerState.terms.check02 === true &&
					this.registerState.terms.check03 === true &&
					this.registerState.terms.check04 === true &&
					this.registerState.terms.check05 === true &&
					this.registerState.terms.check06 === true &&
					this.registerState.terms.check07 === true &&
					this.registerState.terms.check08 === true &&
					this.registerState.terms.check09 === true &&
					this.registerState.terms.check10 === true
				) {
					this.registerState.terms.checkAll = true;
				} else {
					this.registerState.terms.checkAll = false;
				}
			}
		},
	},
};
</script>

<style scoped>
@import '~@/assets/css/bootstrap.css';
</style>
